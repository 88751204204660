import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { withTranslation, useTranslation } from 'react-i18next';

// import { selectConversation } from "../store/reducers/conversations";
import {
  BsThreeDotsVertical,
  BsEmojiSmile,
  BsShareFill,
  BsSearch,
  BsFileArrowUp,
  BsFillImageFill,
  BsFillFileEarmarkFill,
} from 'react-icons/bs';
import { FaFileAudio } from 'react-icons/fa';
import { AiOutlineAudio, AiOutlinePauseCircle } from 'react-icons/ai';
import { VscNotebookTemplate } from 'react-icons/vsc';
import { IconContext } from 'react-icons';
import { ImBin } from 'react-icons/im';
import { BiSend } from 'react-icons/bi';
import { X } from 'react-bootstrap-icons';
import MsgRow from '../_component/MsgRow';
import EmojiBox from '../_component/EmojiBox';
import EmojiPicker from 'emoji-picker-react';

import { conversationSlice, selectConversation } from '../store/reducers/conversations';
import { selectAuth } from '../store/reducers/auth';
import ConversationContext, { useConversation } from './context/ConversationContext';
import { Store } from 'react-notifications-component';

import { Button, Form, InputGroup, Image } from 'react-bootstrap';
import CustomModal from './CustomModal';
import CustomModal1 from './CustomModal1';
import { POST, GET, apiGenerator } from '../api/api-manager';

import { forwardMessage } from '../store/reducers/conversations';

// boostrap
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import { Tab } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// vmsg (For audio(Specifically MP3) recording)
import vmsg from 'vmsg';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import MarkedMessageSlide from './MarkedMessageSlide';
import SearchMessageSlide from './SearchMessageSlide';
import SearchMessageSlide1 from './SearchMessageSlide1';
import StarredMessageSlide from './StarredMessageSlide';
import { usePermissions } from './context/AuthContext';
import { useSystemInfo } from './context/SystemInfoContext';

dayjs.extend(relativeTime);

const API_BASE_URL = process.env.REACT_APP_API_URL_DEV;

const MAX_FILE_SIZE = {
  audio: {
    byte: 16777216, // 16MB
    displayString: '16MB',
  },
  document: {
    byte: 104857600, // 100MB
    displayString: '100MB',
  },
  image: {
    byte: 5242880, // 5MB
    displayString: '55MB',
  },
  video: {
    byte: 16777216, // 16MB
    displayString: '16MB',
  },
};

const renderTooltip = props => {
  const { content, ...toolTipProps } = props;

  return (
    <Tooltip {...toolTipProps} id="button-tooltip">
      {content}
    </Tooltip>
  );
};

// vmsg
const recorder = new vmsg.Recorder({
  wasmURL: `${process.env.PUBLIC_URL}/vmsg.wasm`,
  shimURL: `${process.env.PUBLIC_URL}/wasm-polyfill.js`,
});

console.log('Recorder options', {
  wasmURL: `${process.env.PUBLIC_URL}/vmsg.wasm`,
  shimURL: `${process.env.PUBLIC_URL}/wasm-polyfill.js`,
});

const MessageBarButton = React.forwardRef(({ children, style, ...props }, ref) => {
  return (
    <IconContext.Provider value={{ style: { width: '100%', height: '100%' } }}>
      <button
        ref={ref}
        style={{
          border: 'none',
          borderRadius: '50%',
          backgroundColor: 'transparent',
          color: '#54656f',
          padding: '0',
          width: '26px',
          height: '26px',
          ...style,
        }}
        {...props}
      >
        {children}
      </button>
    </IconContext.Provider>
  );
});

const FileUploader = ({ onFileSelectSuccess, onFileSelectError, selectedFile, disabled = false, type }) => {
  const { t } = useTranslation();

  const fileInput = useRef(null);
  const [acceptType, setAcceptType] = useState('');
  const [overlayString, setOverlayString] = useState('');

  useEffect(() => {
    console.log('[FileUploader] on change selected', selectedFile);

    if (selectedFile === null) {
      fileInput.current.value = null;
    }
  }, [selectedFile]);

  useEffect(() => {
    console.log('On change file type');
    switch (type) {
      case 'image':
        setAcceptType('image/jpeg,image/png,video/mp4,video/3gp');
        setOverlayString(t('photo_and_video'));
        break;

      case 'audio':
        setAcceptType('audio/aac,audio/mp4,audio/mp3,audio/mpeg,audio/amr,audio/ogg,audio/ogg; codecs=opus');
        setOverlayString(t('audio'));
        break;

      case 'file':
        setAcceptType('*');
        setOverlayString(t('file'));
        break;

      default:
    }
  }, [type]);

  const handleFileInput = e => {
    // handle validations
    const file = e.target.files[0];
    console.log({ file });

    if (file === undefined) {
      // onFileSelectError({ error: "No file is selected" });
      console.log({ error: 'No file is selected' });
    }

    // Validate file type and file size
    switch (file.type) {
      // Audio
      case 'audio/aac':
      case 'audio/mp4':
      case 'audio/mp3':
      case 'audio/mpeg':
      case 'audio/amr':
      case 'audio/ogg':
      case 'audio/ogg; codecs=opus':
        if (file.size > MAX_FILE_SIZE.audio.byte) {
          onFileSelectError({ error: 'File size cannot exceed more than 16MB' });
          return;
        }
        break;

      // Document
      case 'text/plain':
      case 'application/pdf':
      case 'application/vnd.ms-powerpoint':
      case 'application/msword':
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        if (file.size > MAX_FILE_SIZE.document.byte) {
          onFileSelectError({ error: 'File size cannot exceed more than 16MB' });
          return;
        }
        break;

      // Image
      case 'image/jpeg':
      case 'image/png':
        if (file.size > MAX_FILE_SIZE.image.byte) {
          onFileSelectError({ error: 'File size cannot exceed more than 16MB' });
          return;
        }
        break;

      // Video
      case 'video/mp4':
      case 'video/3gp':
        if (file.size > MAX_FILE_SIZE.video.byte) {
          onFileSelectError({ error: 'File size cannot exceed more than 16MB' });
          return;
        }
        break;

      default:
        onFileSelectError({ error: 'Unsupported file type' });
        return;
    }

    onFileSelectSuccess(file);
  };

  const Icon = () => {
    switch (type) {
      case 'image':
        return (
          <svg viewBox="0 0 53 53" height="1rem" width="1rem" style={{ width: '100%', height: '100%' }}>
            <g>
              <defs>
                <circle id="image-SVGID_1_" cx="26.5" cy="26.5" r="25.5"></circle>
              </defs>
              <clipPath id="image-SVGID_2_">
                <use xlinkHref="#image-SVGID_1_" overflow="visible"></use>
              </clipPath>
              <g clipPath="url(#image-SVGID_2_)">
                <path fill="#AC44CF" d="M26.5-1.1C11.9-1.1-1.1,5.6-1.1,27.6h55.2C54,8.6,41.1-1.1,26.5-1.1z"></path>
                <path
                  fill="#BF59CF"
                  d="M53,26.5H-1.1c0,14.6,13,27.6,27.6,27.6s27.6-13,27.6-27.6C54.1,26.5,53,26.5,53,26.5z"
                ></path>
                <rect x="17" y="24.5" fill="#AC44CF" width="18" height="9"></rect>
              </g>
            </g>
            <g fill="#F5F5F5">
              <path
                id="svg-image"
                d="M18.318 18.25 34.682 18.25C35.545 18.25 36.409 19.077 36.493 19.946L36.5 20.083 36.5 32.917C36.5 33.788 35.68 34.658 34.818 34.743L34.682 34.75 18.318 34.75C17.368 34.75 16.582 34.005 16.506 33.066L16.5 32.917 16.5 20.083C16.5 19.213 17.32 18.342 18.182 18.257L18.318 18.25 34.682 18.25ZM23.399 26.47 19.618 31.514C19.349 31.869 19.566 32.25 20.008 32.25L32.963 32.25C33.405 32.239 33.664 31.848 33.384 31.492L30.702 28.043C30.486 27.774 30.077 27.763 29.861 28.032L27.599 30.759 24.26 26.459C24.045 26.179 23.614 26.179 23.399 26.47ZM31.75 21.25C30.784 21.25 30 22.034 30 23 30 23.966 30.784 24.75 31.75 24.75 32.716 24.75 33.5 23.966 33.5 23 33.5 22.034 32.716 21.25 31.75 21.25Z"
              ></path>
            </g>
          </svg>
        );

      case 'audio':
        return (
          <svg viewBox="0 0 53 53" height="1rem" width="1rem" style={{ width: '100%', height: '100%' }}>
            <g>
              <defs>
                <circle id="camera-SVGID_1_" cx="26.5" cy="26.5" r="25.5"></circle>
              </defs>
              <clipPath id="camera-SVGID_2_">
                <use xlinkHref="#camera-SVGID_1_" overflow="visible"></use>
              </clipPath>
              <g clipPath="url(#camera-SVGID_2_)">
                <path fill="#D3396D" d="M26.5-1.1C11.9-1.1-1.1,5.6-1.1,27.6h55.2C54,8.6,41.1-1.1,26.5-1.1z"></path>
                <path
                  fill="#EC407A"
                  d="M53,26.5H-1.1c0,14.6,13,27.6,27.6,27.6s27.6-13,27.6-27.6C54.1,26.5,53,26.5,53,26.5z"
                ></path>
              </g>
            </g>
            <g fill="#F5F5F5" transform="translate(14.7, 13.7)">
              {/* <path id="svg-camera"
                d="M27.795 17C28.742 17 29.634 17.447 30.2 18.206L30.5 18.609C31.066 19.368 31.958 19.815 32.905 19.815L34.2 19.815C35.746 19.815 37 21.068 37 22.615L37 32C37 34.209 35.209 36 33 36L20 36C17.791 36 16 34.209 16 32L16 22.615C16 21.068 17.254 19.815 18.8 19.815L20.095 19.815C21.042 19.815 21.934 19.368 22.5 18.609L22.8 18.206C23.366 17.447 24.258 17 25.205 17L27.795 17ZM26.5 22.25C23.601 22.25 21.25 24.601 21.25 27.5 21.25 30.399 23.601 32.75 26.5 32.75 29.399 32.75 31.75 30.399 31.75 27.5 31.75 24.601 29.399 22.25 26.5 22.25ZM26.5 24C28.433 24 30 25.567 30 27.5 30 29.433 28.433 31 26.5 31 24.567 31 23 29.433 23 27.5 23 25.567 24.567 24 26.5 24Z">
              </path> */}
              <path
                id="svg-camera"
                d="M11.999,14.942c2.001,0,3.531-1.53,3.531-3.531V4.35c0-2.001-1.53-3.531-3.531-3.531 S8.469,2.35,8.469,4.35v7.061C8.469,13.412,9.999,14.942,11.999,14.942z M18.237,11.412c0,3.531-2.942,6.002-6.237,6.002 s-6.237-2.471-6.237-6.002H3.761c0,4.001,3.178,7.297,7.061,7.885v3.884h2.354v-3.884c3.884-0.588,7.061-3.884,7.061-7.885 L18.237,11.412z"
              ></path>
            </g>
          </svg>
        );

      case 'file':
        return (
          <svg viewBox="0 0 53 53" height="1rem" width="1rem" style={{ width: '100%', height: '100%' }}>
            <g>
              <defs>
                <circle id="document-SVGID_1_" cx="26.5" cy="26.5" r="25.5"></circle>
              </defs>
              <clipPath id="document-SVGID_2_">
                <use xlinkHref="#document-SVGID_1_" overflow="visible"></use>
              </clipPath>
              <g clipPath="url(#document-SVGID_2_)">
                <path fill="#5157AE" d="M26.5-1.1C11.9-1.1-1.1,5.6-1.1,27.6h55.2C54,8.6,41.1-1.1,26.5-1.1z"></path>
                <path
                  fill="#5F66CD"
                  d="M53,26.5H-1.1c0,14.6,13,27.6,27.6,27.6s27.6-13,27.6-27.6C54.1,26.5,53,26.5,53,26.5z"
                ></path>
              </g>
            </g>
            <g fill="#F5F5F5">
              <path
                id="svg-document"
                d="M29.09 17.09c-.38-.38-.89-.59-1.42-.59H20.5c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H32.5c1.1 0 2-.9 2-2V23.33c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM27.5 22.5V18L33 23.5H28.5c-.55 0-1-.45-1-1z"
              ></path>
            </g>
          </svg>
        );

      default:
        return undefined;
    }
  };

  return (
    <div className="file-uploader">
      <input ref={fileInput} type="file" onChange={handleFileInput} style={{ display: 'none' }} accept={acceptType} />
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 0 }}
        overlay={props => renderTooltip({ content: overlayString, placement: 'top-end', ...props })}
      >
        <MessageBarButton
          onClick={e => {
            e.preventDefault();

            console.log({ e, fileInput });
            fileInput.current && fileInput.current.click();
          }}
          className="btn btn-success btn-floating"
          disabled={disabled}
          style={{ width: '53px', height: '53px', position: 'relative', zIndex: 1000 }}
        >
          {/* <Icon /> */}
          <span style={{ position: 'relative', zIndex: 200 }}>
            <Icon />
          </span>
        </MessageBarButton>
      </OverlayTrigger>
    </div>
  );
};

const ReplyToMessage = ({ replyToMsgRowProps, setReplyToMsgRowProps }) => {
  // console.log('<ReplyToMessage />', { replyToMsgRowProps });
  if (typeof replyToMsgRowProps === 'undefined') return null;

  return <div style={{ position: 'relative', display: 'flex', gap: '5px', backgroundColor: '#efeae2', borderLeft: '8px solid #2641D1' ,borderRadius: '5px', padding: '5px', marginBottom: '5px', maxHeight: '130px', overflow: 'auto', }}>
    <X
      style={{  width:'32px', height: '32px', top: 0, right: 0, color: '#666666', backgroundColor: '#ffffff', borderRadius: '100%' }}
      onClick={() => setReplyToMsgRowProps(undefined)}
    />
    <MsgRow
      id={replyToMsgRowProps.id}
      message_id={replyToMsgRowProps.message_id}
      type={replyToMsgRowProps.type}
      msg={replyToMsgRowProps.msg}
      template={replyToMsgRowProps?.template}
      marked={replyToMsgRowProps.marked}
      time={replyToMsgRowProps.time}
      messageType={replyToMsgRowProps.messageType}
      agentName={replyToMsgRowProps?.agentName}
      customerName={replyToMsgRowProps?.customerName}
      isMedia={replyToMsgRowProps.isMedia}
      media_info={replyToMsgRowProps.media_info}
      reaction_emoji_business={replyToMsgRowProps.reaction_emoji_business}
      reaction_emoji_client={replyToMsgRowProps.reaction_emoji_client}
      setForwardMessageId={() => {}}
      setShowForwardModal={() => {}}
      onLoad={() => {}}
      previewMode={true}
      read={replyToMsgRowProps.read}
      conversationMessageBubbleCss={{ maxWidth: '100%' }}
    />
  </div>;
}

const MessageForm = React.forwardRef(
  (
    {
      handleSubmit,
      closeAllInputPopUp,
      setShowEmojiBox,
      showEmojiBox,
      online,
      isRecording,
      setShowAttachmentPopup,
      showAttachmentPopup,
      setShowModal,
      selectedFile,
      recordedAudio,
      textAreaDisabled,
      stopRecording,
      startRecording,
      isFormSubmitting,
      customerID,
      blockKeydownEventRef,
      replyToMsgRowProps,
      setReplyToMsgRowProps,
    },
    ref
  ) => {
    // console.log('Rerender MessageForm', { handleSubmit });
    const { t } = useTranslation();
    const { isAllowUseOfChatGpt, setIsAllowUseOfChatGpt } = useSystemInfo();

    // Constants
    const MESSAGE_TRANSLATE_LANGUAGES = {
      en: t('english'),
      zh: t('traditional_chinese'),
      cn: t('simplified_chinese'),
    };

    // States
    const messageInputRef = useRef();
    const [messageInput, setMessageInput] = useState('');
    const [isProcessingMessage, setIsProcessingMessage] = useState(false);

    const [messageTranslateLanguage, setMessageTranslateLanguage] = useState();

    const messageInputKeydownHandler = e => {
      // console.log('on `messageInputKeydownHandler`', e?.target?.value, e.key);

      if (blockKeydownEventRef.current) {
        e.preventDefault();
        console.log('is submitting form, block operations.');

        return;
      }

      let eKey = e.key;
      let onShift = e.shiftKey;
      // 13 represents the Enter key
      if (eKey === 'Enter' && !onShift && selectedFile == null) {
        e.preventDefault();
        handleSubmit(e);
      }
    };

    // Process message with ChatGPT
    const processMessage = (message, processType, customerId, { language } = {}) => {
      setIsProcessingMessage(true);
      console.log('Invoke processMessage()', { message, processType });

      const notificationId = Store.addNotification({
        message: 'ChatGPT is processing you message...',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: { duration: 0, click: true, touch: true },
      });

      apiGenerator({
        method: 'POST',
        path: `chat_gpts/process_message`,
        body: {
          message,
          processType,
          customerId,
          language,
        },
      }).then(res => {
        console.log('Process message API response', res?.data);
        Store.removeNotification(notificationId);

        if (res?.data?.status !== undefined) {
          if (res.data.status === true) {
            // Success response
            if (res?.data?.params?.data !== undefined) {
              Store.addNotification({
                message: 'ChatGPT has processed your message.',
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: { duration: 5000 },
              });

              setMessageInput(res.data.params.data);

              if (res.data.params.isUsageLimitReached === true) {
                Store.addNotification({
                  message: "ChatGPT function's usage quota has been reached",
                  type: 'info',
                  insert: 'top',
                  container: 'top-right',
                  animationIn: ['animated', 'fadeIn'],
                  animationOut: ['animated', 'fadeOut'],
                  dismiss: { duration: 5000 },
                });

                setIsAllowUseOfChatGpt(false);
              }
            }
          } else {
            // Fail response
            Store.addNotification({
              message: typeof res?.data?.error === 'string' ? res.data.error : 'ChatGPT failed to process the message',
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: { duration: 5000 },
            });

            setIsAllowUseOfChatGpt(false);
          }
        }

        setIsProcessingMessage(false);
      });
    };

    useEffect(() => {
      console.log('On update event listener `messageInputKeydownHandler`.');
      const messageInputEl = messageInputRef.current;

      if (messageInputEl === null || selectedFile !== null) {
        console.log('Not to add keydown listener for message input', { messageInputEl, selectedFile });
        return;
      }

      messageInputEl.addEventListener('keydown', messageInputKeydownHandler);

      return () => {
        messageInputEl.removeEventListener('keydown', messageInputKeydownHandler);
      };
    }, [selectedFile, customerID, textAreaDisabled, handleSubmit, ]);

    React.useImperativeHandle(
      ref,
      () => {
        return {
          getMessageInput() {
            console.log('on return ref');
            return messageInputRef.current;
          },
          setMessageInput(message, newCursorPosition = undefined) {
            setMessageInput(message);

            if (newCursorPosition !== undefined) {
              messageInputRef.current.selectionEnd = newCursorPosition;
            }
          },
          clearMessageInput() {
            return setMessageInput('');
          },
        };
      },
      []
    );

    return (
      <Form className="message-form" onSubmit={handleSubmit}>
        <Form.Group>
          <InputGroup className="mb-3" style={{ display: 'flex' }}>
            <div
              className="left-button-group px-1 d-flex flex-row justify-content-center align-items-center align-self-end"
              style={{ margin: 'auto' }}
            >
              {/* Emoji button */}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 0 }}
                overlay={props => renderTooltip({ content: t('emoji'), placement: 'top-end', ...props })}
              >
                <MessageBarButton
                  onClick={e => {
                    e.preventDefault();
                    messageInputRef.current.focus();
                    closeAllInputPopUp();
                    setShowEmojiBox(!showEmojiBox);
                  }}
                  disabled={!online || isRecording}
                  style={{ marginRight: '10px' }}
                >
                  {/* <BsEmojiSmile {...props} /> */}
                  <svg viewBox="0 0 24 24" height="1rem" width="1rem" style={{ width: '100%', height: '100%' }}>
                    <path
                      fill="currentColor"
                      d="M9.153,11.603c0.795,0,1.439-0.879,1.439-1.962S9.948,7.679,9.153,7.679 S7.714,8.558,7.714,9.641S8.358,11.603,9.153,11.603z M5.949,12.965c-0.026-0.307-0.131,5.218,6.063,5.551 c6.066-0.25,6.066-5.551,6.066-5.551C12,14.381,5.949,12.965,5.949,12.965z M17.312,14.073c0,0-0.669,1.959-5.051,1.959 c-3.505,0-5.388-1.164-5.607-1.959C6.654,14.073,12.566,15.128,17.312,14.073z M11.804,1.011c-6.195,0-10.826,5.022-10.826,11.217 s4.826,10.761,11.021,10.761S23.02,18.423,23.02,12.228C23.021,6.033,17.999,1.011,11.804,1.011z M12,21.354 c-5.273,0-9.381-3.886-9.381-9.159s3.942-9.548,9.215-9.548s9.548,4.275,9.548,9.548C21.381,17.467,17.273,21.354,12,21.354z  M15.108,11.603c0.795,0,1.439-0.879,1.439-1.962s-0.644-1.962-1.439-1.962s-1.439,0.879-1.439,1.962S14.313,11.603,15.108,11.603z"
                    ></path>
                  </svg>
                </MessageBarButton>
              </OverlayTrigger>
              {/* File upload button */}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 0 }}
                overlay={props => renderTooltip({ content: t('attach'), placement: 'top-end', ...props })}
              >
                <MessageBarButton
                  onClick={e => {
                    e.preventDefault();
                    messageInputRef.current.focus();
                    closeAllInputPopUp();
                    setShowAttachmentPopup(!showAttachmentPopup);
                  }}
                  disabled={!online || isRecording}
                  style={{ marginRight: '10px' }}
                >
                  {/* <BsFileArrowUp /> */}
                  <svg viewBox="0 0 24 24" height="1rem" width="1rem" style={{ width: '100%', height: '100%' }}>
                    <path
                      fill="currentColor"
                      d="M1.816,15.556v0.002c0,1.502,0.584,2.912,1.646,3.972s2.472,1.647,3.974,1.647 c1.501,0,2.91-0.584,3.972-1.645l9.547-9.548c0.769-0.768,1.147-1.767,1.058-2.817c-0.079-0.968-0.548-1.927-1.319-2.698 c-1.594-1.592-4.068-1.711-5.517-0.262l-7.916,7.915c-0.881,0.881-0.792,2.25,0.214,3.261c0.959,0.958,2.423,1.053,3.263,0.215 c0,0,3.817-3.818,5.511-5.512c0.28-0.28,0.267-0.722,0.053-0.936c-0.08-0.08-0.164-0.164-0.244-0.244 c-0.191-0.191-0.567-0.349-0.957,0.04c-1.699,1.699-5.506,5.506-5.506,5.506c-0.18,0.18-0.635,0.127-0.976-0.214 c-0.098-0.097-0.576-0.613-0.213-0.973l7.915-7.917c0.818-0.817,2.267-0.699,3.23,0.262c0.5,0.501,0.802,1.1,0.849,1.685 c0.051,0.573-0.156,1.111-0.589,1.543l-9.547,9.549c-0.756,0.757-1.761,1.171-2.829,1.171c-1.07,0-2.074-0.417-2.83-1.173 c-0.755-0.755-1.172-1.759-1.172-2.828l0,0c0-1.071,0.415-2.076,1.172-2.83c0,0,5.322-5.324,7.209-7.211 c0.157-0.157,0.264-0.579,0.028-0.814c-0.137-0.137-0.21-0.21-0.342-0.342c-0.2-0.2-0.553-0.263-0.834,0.018 c-1.895,1.895-7.205,7.207-7.205,7.207C2.4,12.645,1.816,14.056,1.816,15.556z"
                    ></path>
                  </svg>
                </MessageBarButton>
              </OverlayTrigger>
              {/* Template button */}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 0 }}
                overlay={props => renderTooltip({ content: t('message_template'), placement: 'top-end', ...props })}
              >
                <MessageBarButton
                  onClick={e => {
                    e.preventDefault();
                    closeAllInputPopUp();
                    setShowModal(true);
                    // console.log(modalRef)
                  }}
                  disabled={!online || isRecording}
                  style={{ marginRight: '10px' }}
                > 
                  <VscNotebookTemplate />
                </MessageBarButton>
              </OverlayTrigger>
            </div>
            {textAreaDisabled ? (
              // show button if disabled
              <Button
                style={{ flexGrow: 1, textAlign: 'center', color: '#2d353c' }}
                variant="light"
                onClick={e => {
                  e.preventDefault();
                  setShowModal(true);
                }}
                disabled={!online}
              >
                {t('conversation_expired_send_template')}
              </Button>
            ) : isRecording ? (
              <Button style={{ flexGrow: 1, textAlign: 'center' }} variant="light" disabled>
                {t('recording')}
              </Button>
            ) : (
              <div style={{ flex: 1 }}>
                <ReplyToMessage replyToMsgRowProps={replyToMsgRowProps} setReplyToMsgRowProps={setReplyToMsgRowProps} />
                {/* ChatGPT function buttons */}
                <div style={{ marginBottom: '5px', display: 'flex', gap: '5px' }}>
                  {/* Improve button */}
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    style={{ flex: 1 }}
                    onClick={e => {
                      processMessage(messageInput, 'improve', customerID);
                    }}
                    disabled={
                      messageInput === '' ||
                      isProcessingMessage ||
                      !online ||
                      textAreaDisabled ||
                      selectedFile?.type?.split('/')?.[0] === 'audio' ||
                      isRecording ||
                      !isAllowUseOfChatGpt
                    }
                  >
                    <i className="fa fa-wand-magic" style={{ marginRight: '5px' }}></i>
                    {t('improve_with_chat_gpt')}
                  </button>
                  {/* Translate button */}
                  <div className="btn-group dropup" style={{ flex: 1, display: 'flex' }}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ flex: 1 }}
                      disabled={
                        messageInput === '' ||
                        messageTranslateLanguage === undefined ||
                        isProcessingMessage ||
                        !online ||
                        textAreaDisabled ||
                        selectedFile?.type?.split('/')?.[0] === 'audio' ||
                        isRecording ||
                        !isAllowUseOfChatGpt
                      }
                      onClick={() =>
                        processMessage(messageInput, 'translate', customerID, { language: messageTranslateLanguage })
                      }
                    >
                      <i className="fa fa-language" style={{ marginRight: '5px' }}></i>
                      {messageTranslateLanguage !== undefined
                        ? `${t('translate_to_with_chat_gpt_1')} ${
                            MESSAGE_TRANSLATE_LANGUAGES[messageTranslateLanguage]
                          } ${t('translate_to_with_chat_gpt_2')}`
                        : t('translate_with_chat_gpt')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle"
                      style={{ flex: 0 }}
                      data-bs-toggle="dropdown"
                      disabled={
                        messageInput === '' ||
                        isProcessingMessage ||
                        !online ||
                        textAreaDisabled ||
                        selectedFile?.type?.split('/')?.[0] === 'audio' ||
                        isRecording ||
                        !isAllowUseOfChatGpt
                      }
                    >
                      <i className="fa fa-caret-up"></i>
                    </button>
                    <div className="dropdown-menu">
                      <a href="#/" className="dropdown-item" onClick={() => setMessageTranslateLanguage('en')}>
                        {t('english')}
                      </a>
                      <a href="#/" className="dropdown-item" onClick={() => setMessageTranslateLanguage('zh')}>
                        {t('traditional_chinese')}
                      </a>
                      <a href="#/" className="dropdown-item" onClick={() => setMessageTranslateLanguage('cn')}>
                        {t('simplified_chinese')}
                      </a>
                    </div>
                  </div>
                </div>
                {/* Message text input */}
                <TextareaAutosize
                  className="form-control"
                  as="textarea"
                  required={selectedFile === null && recordedAudio === null}
                  ref={messageInputRef}
                  onChange={e => {
                    setMessageInput(e.target.value);
                  }}
                  value={messageInput}
                  id="messageTextArea"
                  placeholder={t('type_message')}
                  style={{ height: '115px', resize: 'none' }}
                  disabled={
                    !online ||
                    textAreaDisabled ||
                    selectedFile?.type?.split('/')?.[0] === 'audio' ||
                    isRecording ||
                    isProcessingMessage
                  }
                  maxRows={5}
                />
              </div>
            )}
            <div
              className="px-1 d-flex flex-row justify-content-center align-items-center gap-1 align-self-end"
              style={{ margin: 'auto' }}
            >
              {isRecording
                ? messageInput === '' &&
                  !selectedFile && (
                    // Abandon recording button
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 0 }}
                      overlay={props =>
                        renderTooltip({ content: t('abandon_recording'), placement: 'top-start', ...props })
                      }
                    >
                      <MessageBarButton
                        onClick={stopRecording}
                        style={{ marginLeft: '10px', width: '16px', height: '22px' }}
                      >
                        {/* <ImBin /> */}
                        <svg viewBox="0 0 16 22" height="1rem" width="1rem" style={{ width: '100%', height: '100%' }}>
                          <path
                            d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </MessageBarButton>
                    </OverlayTrigger>
                  )
                : messageInput === '' &&
                  !selectedFile && (
                    // Start recording button
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 0 }}
                      overlay={props =>
                        renderTooltip({ content: t('start_recording'), placement: 'top-start', ...props })
                      }
                    >
                      <MessageBarButton
                        className="btn btn-success"
                        onClick={startRecording}
                        disabled={!online}
                        style={{ marginLeft: '10px', width: '24px', height: '24px' }}
                      >
                        {/* <AiOutlineAudio /> */}
                        <svg viewBox="0 0 24 24" height="1rem" width="1rem" style={{ width: '100%', height: '100%' }}>
                          <path
                            fill="currentColor"
                            d="M11.999,14.942c2.001,0,3.531-1.53,3.531-3.531V4.35c0-2.001-1.53-3.531-3.531-3.531 S8.469,2.35,8.469,4.35v7.061C8.469,13.412,9.999,14.942,11.999,14.942z M18.237,11.412c0,3.531-2.942,6.002-6.237,6.002 s-6.237-2.471-6.237-6.002H3.761c0,4.001,3.178,7.297,7.061,7.885v3.884h2.354v-3.884c3.884-0.588,7.061-3.884,7.061-7.885 L18.237,11.412z"
                          ></path>
                        </svg>
                      </MessageBarButton>
                    </OverlayTrigger>
                  )}

              {(isRecording || messageInput !== '' || selectedFile) && (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 0 }}
                  overlay={props => renderTooltip({ content: t('send'), placement: 'top-start', ...props })}
                >
                  <MessageBarButton
                    type={'submit'}
                    disabled={!online || isFormSubmitting}
                    style={{
                      marginLeft: '10px',
                      width: isRecording ? '35px' : '24px',
                      height: isRecording ? '35px' : '24px',
                    }}
                  >
                    {/* <BiSend /> */}
                    {isRecording ? (
                      <svg viewBox="0 0 35 35" height="1rem" width="1rem" style={{ width: '100%', height: '100%' }}>
                        <path
                          d="M17.5,0h0A17.51,17.51,0,0,1,35,17.5h0A17.51,17.51,0,0,1,17.5,35h0A17.51,17.51,0,0,1,0,17.5H0A17.51,17.51,0,0,1,17.5,0Z"
                          fill="#00a884"
                        ></path>
                        <path
                          className="iwt3stqw s79hpmcy ksz6vod1"
                          d="M25.64,18.55,11.2,24.93a.86.86,0,0,1-1.13-.44.83.83,0,0,1-.06-.44l.48-4.11a1.36,1.36,0,0,1,1.24-1.19l7.51-.6a.16.16,0,0,0,.14-.16.16.16,0,0,0-.14-.14l-7.51-.6a1.36,1.36,0,0,1-1.24-1.19L10,12a.84.84,0,0,1,.74-.94.87.87,0,0,1,.45.06l14.44,6.38a.61.61,0,0,1,.31.79A.59.59,0,0,1,25.64,18.55Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    ) : (
                      // Send arrow icon.
                      <svg viewBox="0 0 24 24" height="1rem" width="1rem" style={{ width: '100%', height: '100%' }}>
                        <path
                          fill="currentColor"
                          d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845 L1.101,21.757z"
                        ></path>
                      </svg>
                    )}
                  </MessageBarButton>
                </OverlayTrigger>
              )}
            </div>
          </InputGroup>
        </Form.Group>
      </Form>
    );
  }
);

const extractVariableNumbers = bodyText => {
  console.log('extractVariableNumbers()', { bodyText });

  // Extract variable blocks using regular expression
  const variableBlocks = bodyText.match(/{{(.*?)}}/g);

  console.log({ variableBlocks });

  // Remove the double curly braces from variable blocks
  const varaibleNumbers = Array.isArray(variableBlocks)
    ? variableBlocks.map(block => parseInt(block.replace(/{{|}}/g, '')))
    : [];

  const sortedVariableNumbers = varaibleNumbers.toSorted((a, b) => a - b);
  const uniqueVariableNumbers = sortedVariableNumbers.filter(
    (item, pos) => sortedVariableNumbers.indexOf(item) === pos
  );

  return {
    original: varaibleNumbers,
    sorted: sortedVariableNumbers,
    unique: uniqueVariableNumbers,
  };
};

// * Custom hooks.
const useTemplateBodyTextVariable = (bodyText) => {
  console.log('useTemplateBodyTextVariable()', { bodyText });

  const [bodyTextVariables, setBodyTextVariables] = useState([]);

  useEffect(() => {
    if (bodyText !== '') {
      setBodyTextVariables(bodyTextVariables => {
        // Update body text variable inputs.
        let extractedVariableNumbers = extractVariableNumbers(bodyText);
        const newBodyTextVariables = [];

        for (let index = 0; index < extractedVariableNumbers.unique.length; index++) {
          if (typeof bodyTextVariables?.[index] !== 'undefined') {
            // * Has existing variable value.
            console.log('Has existing variable value.', bodyTextVariables[index]);
            newBodyTextVariables.push(bodyTextVariables[index]);
            continue;
          }

          // * No existing variable value.
          newBodyTextVariables.push({
            value: '',
          });
        }

        console.log('test test', { bodyText, bodyTextVariables, newBodyTextVariables });

        return newBodyTextVariables;
      });
    }
  }, [bodyText, JSON.stringify(bodyTextVariables)]);

  return { bodyTextVariables, setBodyTextVariables };
};

const ConversationWindow = props => {
  const { t, online } = props;
  const dispatch = useDispatch();
  const conversationContext = useContext(ConversationContext);
  const { permissions } = usePermissions();
  const [showEmojiBox, setShowEmojiBox] = useState(false);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [cursorPosition, setCursorPosition] = useState();
  const messageFormRef = useRef();
  const authState = useSelector(selectAuth);
  const conversation = useSelector(selectConversation);
  const {
    customerID,
    customerName,
    customerPhone,
    sendMessage,
    sendTemplate,
    updateWindowChat,
    loadMore,
    getMessageLimit,
    updateRoomList,
    checkCurrentExpiration,
  } = useConversation();

  const [showModal, setShowModal] = useState(false);

  const [showForwardModal, setShowForwardModal] = useState(false);
  const [forwardMessageId, setForwardMessageId] = useState(null);
  const [forwardRecipients, setForwardRecipients] = useState([]);

  const [templateMessage, setTemplateMessage] = useState([]);

  const [modalComponent, setModalComponent] = useState([]);
  const [templateId, setTemplateId] = useState();
  const [templateBodyText, setTemplateBodyText] = useState('');
  const { bodyTextVariables, setBodyTextVariables } = useTemplateBodyTextVariable(templateBodyText);

  const [textAreaDisabled, setTextAreaDisabled] = useState(false);

  // File hook
  const [selectedFile, setSelectedFile] = useState(null);

  // Slide Marked Message Hook
  const [showMarkedMessage, setShowMarkedMessage] = useState(false);

  // Slide Search Message Hook
  const [showSearchMessage, setShowSearchMessage] = useState(false);
  const [messageFocus, setMessageFocus] = useState(undefined); // for search and marked message (scrolling)

  const closeAllInputPopUp = () => {
    setShowEmojiBox(false);
    setShowAttachmentPopup(false);
    setShowModal(false);
  };

  // Voice note hooks
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null); // Storing blob

  // Reply to states.
  const [replyToMsgRowProps, setReplyToMsgRowProps] = useState(undefined);

  const fileKeyDownHandler = e => {
    console.log('on `fileKeyDownHandler`', e.key);
    if (blockKeydownEventRef.current) {
      e.preventDefault();
      console.log('is submitting form, block operations.');

      return;
    }

    let eKey = e.key;
    // 13 represents the Enter key
    if (eKey === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const startRecording = async e => {
    e.preventDefault();

    // Start recording
    try {
      await recorder.initAudio();
      await recorder.initWorker();
      recorder.startRecording();
      setIsRecording(true);
      setShowAttachmentPopup(false);
    } catch (e) {
      console.error(e);
    }
  };

  const stopRecording = async e => {
    e.preventDefault();

    // Stop recording
    const blob = await recorder.stopRecording();
    setIsRecording(false);
    setRecordedAudio(blob);

    return blob;
  };

  // Form hook
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const blockKeydownEventRef = useRef(false);

  useEffect(() => {
    console.log('On change customer ID');
    setShowSearchMessage(false);
    setShowMarkedMessage(false);
  }, [customerID]);

  useEffect(() => {
    console.log('On update event listener `fileKeyDownHandler`.');
    console.log({ selectedFile });
    // let ele = messageFormRef.current.getMessageInput();

    if (selectedFile != null) {
      document.addEventListener('keydown', fileKeyDownHandler);
      // ele.removeEventListener('keydown', keyDownHandler);
    }
    //  else {
    //   document.removeEventListener('keydown', fileKeyDownHandler);
    //   // ele.addEventListener('keydown', keyDownHandler);
    // }

    return () => {
      document.removeEventListener('keydown', fileKeyDownHandler);
    };
  }, [selectedFile, customerID]);
  // File hook - end

  // useEffect(() => {
  //   console.log('On change cursor position');
  //   messageFormRef.current.getMessageInput().selectionEnd = cursorPosition;
  // }, [cursorPosition]);

  useEffect(() => {
    console.log('On show modal');

    if (showModal === true) {
      apiGenerator({
        method: 'GET',
        path: `message_templates`,
        query: {
          params: {
            status: 'APPROVED',
            // status: 'PENDING',
          },
        },
      }).then(res => {
        console.log(res);
        if (res?.data?.params?.docs) setTemplateMessage(res.data.params.docs);
      });
    }
  }, [showModal]);

  useEffect(() => {
    console.log('One time useEffect');
    updateWindowChat(customerID, customerName, customerPhone);
    // const scrollDiv = document.getElementById('conversation-window')
    // scrollDiv.scrollTop = 0;

    const scrollDiv = document.getElementById('conversation-window');
    const handleScroll = () => {
      // console.log(scrollDiv.scrollTop);
      if (scrollDiv.scrollTop < 1) {
        scrollDiv.scrollTop = 1;
      }
    };

    if (scrollDiv !== null) {
      scrollDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      scrollDiv.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log(`On change message list`, conversation.messageList);
    // add disabled to textarea if last msg is over 24hrs
    if (conversation.messageList.length > 0) {
      // MOVED to checkCurrentExpiration();
      setTextAreaDisabled(checkCurrentExpiration());

      // message list update from loadmore and (search || marked) message
      // need to scroll to specific message
      // check if the message existed in messaage list, call loadMore until the messageFocus id is exist
      console.log(`[message focus]update message list : ${messageFocus}`);
      if (!messageFocus) {
        scrollDown();
      } else {
        // message focus found
        const exists = conversation.messageList.some(v => v._id == messageFocus);
        if (exists) {
          scrollToMessage(messageFocus);
        } else {
          loadMore({ limit: -1, after: messageFocus, before: conversation.messageList.length > 0 ? conversation.messageList[conversation.messageList.length - 1]._id : undefined });
        }
      }

      // timer for refreshing page getting coveration expiration time
      const MINUTE_MS = 60000;
      const interval = setInterval(() => {
        if (customerID != null) {
          let expired = checkCurrentExpiration();
          setTextAreaDisabled(expired);
        }
      }, MINUTE_MS);

      console.log('Mark read message');
      conversationContext.markMessageAsRead(customerID);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    } else {
      // no message
      setTextAreaDisabled(true);
    }
  }, [conversation.messageList]);

  useEffect(() => {
    console.log('On select file');

    if (selectedFile) {
      setShowAttachmentPopup(false);
    }
  }, [selectedFile]);

  useEffect(() => {
    console.log('On conversation refreshed');
    if (conversation.conversationsRefreshed) {
      updateWindowChat(customerID, customerName, customerPhone);
      updateRoomList();
    }
  }, [conversation.conversationsRefreshed]);

  // for marked || search message hook filtering message list and scrolling
  useEffect(() => {
    console.log(`messageFocus: ${messageFocus}`);
    if (messageFocus != null) {
      const exists = conversation.messageList.some(v => v._id == messageFocus);
      if (exists) {
        scrollToMessage(messageFocus);
      } else {
        loadMore({ limit: -1, after: messageFocus, before: conversation.messageList.length > 0 ? conversation.messageList[conversation.messageList.length - 1]._id : undefined });
      }
    } else {
      console.log(`messageFocus undefined`);
    }
  }, [messageFocus]);

  useEffect(() => {
    if (isFormSubmitting === false) blockKeydownEventRef.current = false;
  }, [isFormSubmitting]);

  useEffect(() => {
    console.log({ newTemplateId: templateId });
  }, [templateId]);

  useEffect(() => {
    console.log('useEffect()', { replyToMessage: replyToMsgRowProps });
  }, [replyToMsgRowProps]);
  

  const scrollToMessage = msg_id => {
    const section = document.querySelector(`#msg-${msg_id}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // clear focus
    setMessageFocus(undefined);
  };

  const scrollDown = () => {
    const scrollDiv = document.getElementById('conversation-window');

    // check load more (msg limit)
    if (conversation.messageList.length > getMessageLimit()) {
      // scrollDiv.scrollTop = 0;
    } else {
      scrollDiv.scrollTop = scrollDiv.scrollHeight;
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    blockKeydownEventRef.current = true;
    console.log('handleSubmit()', { customerID, replyToMsgRowProps: replyToMsgRowProps });

    try {
      const ref = messageFormRef.current.getMessageInput();

      if (!isRecording) {
        if (ref.value == '' && selectedFile == null) {
          blockKeydownEventRef.current = false;
          return;
        }
      }

      console.log('ref.value', ref?.value);
      console.log('selectedFile', selectedFile);

      setIsFormSubmitting(true);
      if (isRecording) {
        const recordedAudio = await stopRecording(e);
        console.log({ recordedAudio });
        await sendMessage(customerID, '', recordedAudio, replyToMsgRowProps?.id);
        setRecordedAudio(null);
      } else if (selectedFile) {
        await sendMessage(customerID, ref.value, selectedFile, replyToMsgRowProps?.id);
      } else {
        await sendMessage(customerID, ref.value, undefined, replyToMsgRowProps?.id);
      }

      if (selectedFile?.type?.split('/')?.[0] !== 'audio' && !isRecording) {
        // Only clear message when not sending audio
        // While sending audio file, the text area is disabled
        ref.value = '';
      }

      if (selectedFile) {
        setSelectedFile(null);
      }

      setReplyToMsgRowProps(undefined); // Remove selected reply message.

      // Clear message inputs
      messageFormRef.current.clearMessageInput();

      // document.removeEventListener('keydown', fileKeyDownHandler);
      // let ele = messageFormRef.current.getMessageInput();
      // ele.addEventListener('keydown', keyDownHandler);
    } catch (error) {
      console.log('Unable to send message', error);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const addEmoji = ({ emoji }) => {
    const ref = messageFormRef.current.getMessageInput();
    // console.log(`adding Emoji`)
    // console.log(emoji)

    console.log('ref', ref.value);
    ref.focus();
    const start = ref.value.substring(0, ref.selectionStart);
    const end = ref.value.substring(ref.selectionStart);
    const text = start + emoji + end;

    messageFormRef.current.setMessageInput(text, start.length + emoji.length);
    // ref.value = text;
    // messageFormRef.current.getMessageInput().selectionEnd = start.length + emoji.length;
    // setCursorPosition(start.length + emoji.length);
  };

  // * Message template modal hooks
  const handleModalMessageTemplateOnSubmit = () => {
    console.log('handleModalMessageTemplateOnSubmit');
    console.log('template ID', templateId);
    // console.log('params', modalComponent);
    // const components = bodyTextVariables.map(bodyTextVariable => {bodyTextVariable.value})
    const components = bodyTextVariables.map(bodyTextVariable => {
      return { type: 'text', text: bodyTextVariable.value };
    })
    console.log({ components });

    sendTemplate(customerID, templateId, components);
  };

  const handleForwardModalOnSubmit = () => {
    console.log('handleForwardModalOnSubmit');
    console.log(forwardRecipients);

    dispatch(
      POST(forwardMessage, `conversations/forward/${forwardMessageId}`, {
        recipients: forwardRecipients,
      })
    );

    setForwardRecipients([]);
  };

  // const modalMessageTemplateOnSelect = (eventKey, e) => {
  //   console.log('modalMessageTemplateOnSelect()', { eventKey, e });
  //   setTemplateId(eventKey);
  //   // setTemplateBodyText(eventKey);

  //   // ! Deprecated.
  //   // clear component
  //   setModalComponent([]);
  // };

  const modalMessageTemplateOnClick = (e, template) => {
    console.log('modalMessageTemplateOnClick()', { e, template });
    setTemplateId(`#${template._id}`);

    let newTemplateBodyText;

    // Loop through the components to find the BODY type component
    for (const component of template.components) {
      if (component.type === 'BODY') {
        newTemplateBodyText = component.text;
        break;
      }
    }

    // Now `newTemplateBodyText` contains the body text
    console.log({ newTemplateBodyText });

    if (typeof newTemplateBodyText === 'string') {
      setTemplateBodyText(newTemplateBodyText);
    }
  };

  const handleChangeBodyTextVariable = (e, index) => {
    setBodyTextVariables(prev => {
      prev[index] = { value: e.target.value };
      return [...prev];
    });
  };

  const ModalMessageTemplate = () => {
    // var params_arr = [];
    // const params_regex = /\{\{(.*?)\}\}/g;

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Tab.Container id="list-group-tabs-example">
            <Row>
              {/* template tab */}
              <Col sm={4}>
                <ListGroup>
                  {templateMessage &&
                    templateMessage.map((value, index) => {
                      return (
                        <ListGroup.Item
                          action
                          href={`#${value._id}`}
                          id={value._id}
                          key={`item_${value._id}`}
                          onClick={(e) => {
                            modalMessageTemplateOnClick(e, value)
                          }}
                        >
                          {value.name}
                        </ListGroup.Item>
                      );
                    })}
                </ListGroup>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {templateMessage &&
                    templateMessage.map((value, index) => {
                      return (
                        <Tab.Pane eventKey={`#${value._id}`} key={`pane_${value._id}`}>
                          <h5>Preview: </h5>
                          <div className="conversation-message-row d-flex flex-row-reverse">
                            <div
                              className="conversation-message-bubble to-msg align-self-end"
                              style={{ maxWidth: '100%' }}
                            >
                              {value.components.map((component, c_index) => {
                                if (component.type === 'HEADER') {
                                  // params_arr['header'] = component.text.match(params_regex);
                                  const headerMediaStorage = Array.isArray(value?.mediaStorage)
                                    ? value.mediaStorage.find(mediaStorage => mediaStorage.component === 'header')
                                    : undefined;

                                  const s3PresignedUrl = headerMediaStorage?.presigned_url;
                                  const mimeType = headerMediaStorage?.mime_type;

                                  switch (component.format) {
                                    case 'IMAGE':
                                      return typeof s3PresignedUrl !== 'undefined' ? (
                                        <Image src={s3PresignedUrl} className="msg-image" />
                                      ) : (
                                        <></>
                                      );

                                    case 'VIDEO':
                                      return typeof s3PresignedUrl !== 'undefined' ? (
                                        <video width="320" height="240" controls>
                                          <source src={s3PresignedUrl} type={mimeType} />
                                        </video>
                                      ) : (
                                        <></>
                                      );

                                    case 'DOCUMENT':
                                      return typeof s3PresignedUrl !== 'undefined' ? (
                                        <iframe src={s3PresignedUrl} width="320" height="240"></iframe>
                                      ) : (
                                        <></>
                                      );

                                    case 'TEXT':
                                      return (
                                        <div className="msg-header" style={{ color: 'black' }}>
                                          {component.text}
                                        </div>
                                      );

                                    default:
                                      return <></>;
                                  }
                                } else if (component.type === 'BODY') {
                                  // params_arr['body'] = component.text.match(params_regex);
                                  return (
                                    <div
                                      className="msg-body"
                                      style={{ color: 'black' }}
                                      // key={`${value._id}_component_${c_index}`}
                                    >
                                      <div className='msg-text'>
                                        <div className='msg-text'>{component.text}</div>
                                      </div>
                                    </div>
                                  );
                                } else if (component.type === 'FOOTER') {
                                  // params_arr['footer'] = component.text.match(params_regex);
                                  return (
                                    <div
                                      className="msg-footer fw-light"
                                      style={{ color: 'grey' }}
                                      // key={`${value._id}_component_${c_index}`}
                                    >
                                      {component.text}
                                    </div>
                                  );
                                }

                                return <></>;
                              })}

                              <div className="msg-time fw-light" style={{ color: 'grey' }}>
                                {dayjs().to(dayjs(new Date()))}
                              </div>
                            </div>
                          </div>
                          
                          {/* Variable. */}
                          {`#${value._id}` === templateId && bodyTextVariables.length > 0 && (
                            <div>
                              {/* <h6>Body Text Variables</h6> */}
                              <label className="form-label" htmlFor="name">
                                Template Variables
                              </label>
                              <div>
                                {bodyTextVariables.map((bodyTextVariable, index) => (
                                  <div
                                    key={index}
                                    style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '5px' }}
                                  >
                                    <div>{`{{${index + 1}}}`}</div>
                                    <input
                                      type="text"
                                      className={`form-control`}
                                      placeholder={`Enter content for {{${index + 1}}}`}
                                      name={`bodyTextVariable[${index}]`}
                                      value={bodyTextVariable.value}
                                      onChange={e => {
                                        handleChangeBodyTextVariable(e, index);
                                      }}
                                      disabled={isFormSubmitting}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}

                          {/* {params_arr['body'] != null &&
                            params_arr['body'].map((body_params, index) => {
                              return (
                                <InputGroup style={{ padding: 5 }} key={`${value._id}_inputgrp_${index}`}>
                                  <InputGroup.Text>
                                    {t('params')}
                                    {index + 1}
                                  </InputGroup.Text>
                                  <Form.Control
                                    key={value._id + index}
                                    type="text"
                                    onChange={e => {
                                      // console.log(e.target.value)
                                      setModalComponent(oldModalComponent => ({
                                        ...oldModalComponent,
                                        [index]: e.target.value,
                                      }));
                                    }}
                                  />
                                </InputGroup>
                              );
                            })} */}
                        </Tab.Pane>
                      );
                    })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </>
    );
  };

  const renderForwardModalContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Tab.Container id="list-group-tabs-example">
            <Row>
              {/* template tab */}
              <Col sm={6}>
                <ListGroup className="mb-4">
                  {conversation.roomList &&
                    conversation.roomList.map((conversation, index) => {
                      let today = new Date().getTime();
                      let conversation_expire_time = new Date(conversation.expiration_timestamp * 1000).getTime();
                      return (
                        <ListGroup.Item
                          key={conversation._id}
                          action
                          active={forwardRecipients?.[conversation._id] !== undefined}
                          href={`#conversation${index}`}
                          onClick={() => {
                            const newSelectedConversations = { ...forwardRecipients };

                            if (newSelectedConversations?.[conversation._id] === undefined) {
                              // Add conversation
                              newSelectedConversations[conversation._id] = conversation;
                            } else {
                              // Remove conversation
                              delete newSelectedConversations[conversation._id];
                            }

                            setForwardRecipients(newSelectedConversations);
                          }}
                          disabled={typeof (conversation.expiration_timestamp) !== 'undefined' && today > conversation_expire_time}
                        >
                          {conversation.profile_name}
                        </ListGroup.Item>
                      );
                    })}
                </ListGroup>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </>
    );
  };

  const onClickMarkedMessage = msg_id => {
    console.log(`onClicked MarkedMessage`, msg_id);

    setMessageFocus(msg_id);
    setShowMarkedMessage(false);
  };

  const onClickSearchMessage = msg_id => {
    console.log(`onClicked SearchMessage`, msg_id);
    setMessageFocus(msg_id);
    setShowSearchMessage(false);
  };

  const onClickReplyToMessage = msg_id => {
    console.log(`onClickReplyToMessage`, msg_id);
    setMessageFocus(msg_id);
  };

  return (
    <>
      <div className={`col-md-9 p-0 d-flex ${customerID ? '' : 'd-none'}`} id="conversation-window-div">
        <div className="conversation-window" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {/* Topbar */}
          <div id="conversation-top-row" className="d-flex flex-nowrap">
            <div className="col-1 d-flex justify-content-center align-items-center">
              <img
                className="profile-pic"
                src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"
                alt="profile-pic"
              />
            </div>
            <div className="col-10 px-2 d-flex flex-column justify-content-center">
              <div className="d-flex justify-content-between">
                <h5>{customerName}</h5>
                <span className="fw-light"></span>
              </div>
              <div>
                <span>{permissions?.view_customer_phone ? customerPhone : ''}</span>
              </div>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center align-items-center">
              <button
                className="btn btn-default"
                onClick={() => {
                  setShowMarkedMessage(false);
                  setShowSearchMessage(!showSearchMessage);
                }}
                style={{ border: 'none', backgroundColor: 'transparent' }}
              >
                <BsSearch />
              </button>
              <button
                className="btn btn-default"
                onClick={() => {
                  setShowSearchMessage(false);
                  setShowMarkedMessage(!showMarkedMessage);
                }}
                style={{ border: 'none', backgroundColor: 'transparent' }}
              >
                <BsThreeDotsVertical />
              </button>
            </div>
          </div>

          {/* Conversation window */}
          <div id="conversation-window" className="flex-fill overflow-auto d-flex flex-column">
            <div id="conversation-container" className="d-flex flex-column-reverse p-3">
              {conversation.messageList &&
                conversation.messageList.length > 0 &&
                conversation.messageList.map((value, index) => {
                  let send_type = 'receive';

                  // media details
                  let isMedia = false;
                  let media_info = {
                    media_id: value._id,
                    media_type: '',
                    mime_type: '',
                    extension: '',
                  };

                  if (value.company_phone_number && value.company_phone_number.includes(value.from)) {
                    send_type = 'send';
                  }
                  //
                  try {
                    if (value.type && value.type != 'text') {
                      isMedia = true;

                      switch (value.type) {
                        case 'sticker':
                          media_info.media_type = 'sticker';
                          media_info.mime_type = value.sticker.mime_type;
                          media_info.extension = value.sticker.extension;
                          media_info.s3PresignedUrl = value?.image?.s3?.presigned_url;

                          break;

                        case 'audio':
                          media_info.media_type = 'audio';
                          media_info.mime_type = value.audio.mime_type;
                          media_info.extension = value.audio.extension;
                          media_info.s3PresignedUrl = value?.audio?.s3?.presigned_url;

                          break;

                        case 'document':
                          media_info.media_type = 'document';
                          media_info.filename = value.document?.filename;
                          media_info.caption = value.document?.caption;
                          media_info.mime_type = value.document.mime_type;
                          media_info.extension = value.document.extension;
                          media_info.s3PresignedUrl = value?.document?.s3?.presigned_url;

                          break;

                        case 'image':
                          media_info.media_type = 'image';
                          media_info.caption = value.image?.caption;
                          media_info.mime_type = value.image.mime_type;
                          media_info.extension = value.image.extension;
                          media_info.s3PresignedUrl = value?.image?.s3?.presigned_url;

                          break;

                        case 'video':
                          media_info.media_type = 'video';
                          media_info.caption = value.video?.caption;
                          media_info.mime_type = value.video.mime_type;
                          media_info.extension = value.video.extension;
                          media_info.s3PresignedUrl = value?.video?.s3?.presigned_url;

                          break;
                        case 'template':
                          isMedia = false;
                          break;
                        default:
                          break;
                      }
                    }
                  } catch (error) {
                    console.log('Error occured while handling media info');
                    console.log(error);
                  }

                  // limit only one reaction for one number

                  if (value.type) {
                    return (
                      <MsgRow
                        id={value._id}
                        message_id={value.id}
                        key={value._id}
                        rowKey={value._id + '_' + index}
                        type={send_type}
                        msg={value.text?.body}
                        template={value?.template}
                        marked={value.marked}
                        time={value.timestamp}
                        messageType={value.type}
                        agentName={value?.agentDoc?.name}
                        customerName={customerName}
                        customerPhone={customerPhone}
                        isMedia={isMedia}
                        media_info={media_info}
                        reaction_emoji_business={value.reaction_business}
                        reaction_emoji_client={value.reaction_client}
                        setForwardMessageId={setForwardMessageId}
                        setShowForwardModal={setShowForwardModal}
                        onLoad={scrollDown}
                        previewMode={false}
                        read={value.read}
                        setReplyToMsgRowProps={setReplyToMsgRowProps}
                        replyToMessage={value.reply_to_message}
                        onClickReplyToMessage={onClickReplyToMessage}
                      />
                    );
                  }
                })}

              {conversation.messageList.length > 0 ? (
                <div className="d-flex justify-content-center" style={{ marginTop: 'auto' }}>
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() =>
                      loadMore({ before: conversation.messageList[conversation.messageList.length - 1]._id })
                    }
                  >
                    {t('click_to_show_more')}
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          {/* Attachment popup */}
          {showAttachmentPopup && (
            <div className="attachment-popup">
              <FileUploader
                onFileSelectSuccess={file => setSelectedFile(file)}
                onFileSelectError={({ error }) => {
                  // alert(error)
                  Store.addNotification({
                    message: error,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: { duration: 5000 },
                  });
                }}
                selectedFile={selectedFile}
                disabled={!online || isRecording}
                type={'image'}
              />
              <FileUploader
                onFileSelectSuccess={file => setSelectedFile(file)}
                onFileSelectError={({ error }) => {
                  // alert(error)
                  Store.addNotification({
                    message: error,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: { duration: 5000 },
                  });
                }}
                selectedFile={selectedFile}
                disabled={!online || isRecording}
                type={'audio'}
              />
              <FileUploader
                onFileSelectSuccess={file => setSelectedFile(file)}
                onFileSelectError={({ error }) => {
                  // alert(error)
                  Store.addNotification({
                    message: error,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: { duration: 5000 },
                  });
                }}
                selectedFile={selectedFile}
                disabled={!online || isRecording}
                type={'file'}
              />
            </div>
          )}

          {/* Message input row */}
          <div id="conversation-input-row" className="d-flex flex-column">
            {/* <EmojiBox show={showEmojiBox} addEmoji={addEmoji}/> */}
            <div className={showEmojiBox ? 'd-block' : 'd-none'}>
              <EmojiPicker
                width={'100%'}
                searchPlaceholder="Search Emoji Here..."
                lazyLoadEmojis={true}
                emojiStyle="apple"
                autoFocusSearch={false}
                previewConfig={{ showPreview: false }}
                onEmojiClick={addEmoji}
              />
            </div>
            {selectedFile && (
              <div className="p-2" style={{ backgroundColor: '#ffffff' }}>
                {t('selected_file')}
                {selectedFile.name}
                <button
                  className="btn btn-danger mx-2"
                  onClick={e => {
                    e.preventDefault();
                    setSelectedFile(null);
                  }}
                >
                  {t('remove')}
                </button>
              </div>
            )}
            <MessageForm
              ref={messageFormRef}
              handleSubmit={handleSubmit}
              closeAllInputPopUp={closeAllInputPopUp}
              setShowEmojiBox={setShowEmojiBox}
              showEmojiBox={showEmojiBox}
              online={online}
              isRecording={isRecording}
              setShowAttachmentPopup={setShowAttachmentPopup}
              showAttachmentPopup={showAttachmentPopup}
              setShowModal={setShowModal}
              selectedFile={selectedFile}
              recordedAudio={recordedAudio}
              textAreaDisabled={textAreaDisabled}
              stopRecording={stopRecording}
              startRecording={startRecording}
              isFormSubmitting={isFormSubmitting}
              customerID={customerID}
              blockKeydownEventRef={blockKeydownEventRef}
              replyToMsgRowProps={replyToMsgRowProps}
              setReplyToMsgRowProps={setReplyToMsgRowProps}
            />
          </div>
        </div>

        {/* Utility sidebar */}
        <SearchMessageSlide1
          showSearchMessage={showSearchMessage}
          setShowSearchMessage={setShowSearchMessage}
          onClickSearchMessage={onClickSearchMessage}
        />
        <StarredMessageSlide
          showMarkedMessage={showMarkedMessage}
          setShowMarkedMessage={setShowMarkedMessage}
          onClickMarkedMessage={onClickMarkedMessage}
          setMessageFocus={setMessageFocus}
        />
      </div>
      <div
        className={`col-md-9 p-0 d-flex flex-column  ${!customerID ? '' : 'd-none'}`}
        style={{ backgroundColor: '#efeae2' }}
      >
        <div style={{ margin: 'auto', fontSize: '2rem', fontWeight: 200 }}>{t('click_contact_start_chatting')}</div>
      </div>

      {/* Slide For Template */}
      {/* <MarkedMessageSlide
        showMarkedMessage={showMarkedMessage}
        setShowMarkedMessage={setShowMarkedMessage}
        onClickMarkedMessage={onClickMarkedMessage}
        setMessageFocus={setMessageFocus}
      /> */}
      {/* <SearchMessageSlide
        showSearchMessage={showSearchMessage}
        setShowSearchMessage={setShowSearchMessage}
        onClickSearchMessage={onClickSearchMessage}
      /> */}

      {/* Modal for template */}
      <CustomModal1
        size="lg"
        showModal={showModal}
        setShowModal={setShowModal}
        modal_title={t('message_template')}
        onSubmit={handleModalMessageTemplateOnSubmit}
      >
        {ModalMessageTemplate()}
      </CustomModal1>
      {/* Modal for forward */}
      <CustomModal1
        showModal={showForwardModal}
        setShowModal={setShowForwardModal}
        modal_title={t('select_recipient')}
        onSubmit={handleForwardModalOnSubmit}
      >
        {renderForwardModalContent()}
      </CustomModal1>
    </>
  );
};

export default withTranslation()(ConversationWindow);
